import * as React from "react"
import PropTypes from "prop-types"
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import theme from "../../src/theme/index"
import useSiteMetadata from "../../src/hooks/use-sitemetadata"

export default function TopLayout(props) {
  const { title, description } = useSiteMetadata()

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {console.log(theme)}
        {props.children}
      </ThemeProvider>
    </React.Fragment>
  )
}

TopLayout.propTypes = {
  children: PropTypes.node,
}
