import palette from "../palette";

export default {
  root: {
    "&:hover": {
      color: palette.primary.main
    }
  },
  underlineHover: {
    "&:hover": {
      textDecoration: "none"
    }
  }
};
