import { createTheme } from "@mui/material/styles"

import components from "./overrides"
import spacing from "./spacing"
import typography from "./typography"
import shadows from "./shadows"
import palette from "./palette"

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1200,
      xl: 1536,
    },
  },
  mixins: {
    toolbar: {
      minHeight: "80px",
    },
  },
  palette,
  shadows: [
    "none",
    "0px 2px 3px rgba(0,0,0,0.04),0px 6px 25px rgba(0,0,0,0.01)",
    "0px 2px 3px rgba(0,0,0,0.07),0px 6px 25px rgba(0,0,0,0.05)",
    "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
    "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
    "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
    "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
    "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
    "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
    "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
    "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
    "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
    "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
    "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
    "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
    "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
    "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
    "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
  ],
  shape: {
    borderRadius: 8,
  },
  spacing: [
    0,
    4,
    8,
    16,
    24,
    32,
    40,
    48,
    56,
    64,
    72,
    80,
    88,
    96,
    104,
    112,
    120,
    128,
    136,
    144,
    152,
    160,
  ],
  zIndex: {
    drawer: 1099,
    appBar: 1098,
    modal: 1300,
  },
  typography: {
    h1: {
      fontSize: "2.5rem",
      fontWeight: "500",
      lineHeight: "1.2",
      letterSpacing: "0em",
    },
    h2: {
      fontSize: "2rem",
      fontWeight: "500",
      lineHeight: "1.25",
      letterSpacing: "0.01em",
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: "500",
      lineHeight: "1.33",
      letterSpacing: "0.01em",
    },
    h4: {
      fontSize: "1.25rem",
      fontWeight: "500",
      lineHeight: "1.4",
      letterSpacing: "0.01em",
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: "500",
      lineHeight: "1.4",
      letterSpacing: "0.01em",
    },
    h6: {
      fontSize: "1.25rem",
      fontWeight: "500",
      lineHeight: "1.4",
      letterSpacing: "0.01em",
    },
    subtitle1: {
      fontSize: "1.25rem",
      fontWeight: "500",
      lineHeight: "1.6",
      letterSpacing: "0.01em",
    },
    subtitle2: {
      fontSize: "1.125rem",
      fontWeight: "400",
      lineHeight: "1.56",
      letterSpacing: "0.01em",
    },
    body1: {
      fontSize: "1rem",
      fontWeight: "400",
      lineHeight: "1.63",
      letterSpacing: "0.015em",
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: "400",
      lineHeight: "1.71",
      letterSpacing: "0.015em",
    },
    button: {
      fontSize: "1rem",
      fontWeight: "400",
      lineHeight: "1.13",
      textTransform: "none",
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: "400",
      lineHeight: "2",
      letterSpacing: "0.015em",
    },
    gutterBottom: {
      marginBottom: "0.75em",
    },
    paragraph: {
      marginBottom: "0.75em",
    },
  },

  components: {
    // BASELINE
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
          overflowWrap: "break-word",
        },

        // "ul, ol": {
        //   listStyleType: "none",
        // },
        a: {
          color: palette.primary.main,
          textDecoration: "none !important",
          "&:hover": {
            textDecoration: "underline",
          },
        },
        "p > a, li > a": {
          color: palette.primary.main,
        },
      },
    },
    // TYPOGRAPHY
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          subtitle1: "p",
          subtitle2: "p",
        },
      },
      styleOverrides: {
        gutterBottom: {
          marginBottom: "0.5em",
        },
      },
    },
    // BUTTON
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
          lineHeight: "1.71429",
        },
        sizeSmall: {
          fontSize: "0.8125rem", // 13
          paddingTop: spacing[1], // 4
          paddingRight: spacing[1] * 1.25, // 5
          paddingBottom: spacing[1], // 4
          paddingLeft: spacing[1] * 1.25, // 5
        },
        sizeMedium: {
          paddingTop: spacing[1] * 1.5, // 6
          paddingRight: spacing[3], // 16
          paddingBottom: spacing[1] * 1.5, // 6
          paddingLeft: spacing[3], // 16
        },
        sizeLarge: {
          fontSize: "1rem", // 15
          paddingTop: spacing[1] * 3, // 12
          paddingRight: spacing[4], // 24
          paddingBottom: spacing[1] * 3,
          paddingLeft: spacing[4],
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    // CARD
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: spacing[4],
        },
      },
    },

    // INPUT
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: palette.white,
          position: "relative",
          lineHeight: "1.4375",
        },
        input: {
          // width: "100%",
        },
        inputSizeSmall: {
          padding: `${spacing[2]}px ${spacing[1] * 3.5}px`,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover fieldset": {
            borderColor: `${palette.blueGray500}`,
          },
        },
        notchedOutline: {
          border: `1px solid ${palette.gray200}`,
        },
      },
    },

    // CHECKBOX

    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },

    // RADIO

    MuiRadio: {
      styleOverrides: {
        root: {
          padding: 0,
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },

    // LIST
    MuiList: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    // CHIP
    MuiChip: {
      styleOverrides: {
        root: {
          height: "auto",
        },
      },
    },
    // TAB
    MuiTab: {
      styleOverrides: {
        root: {
          "&>*:first-child": {
            marginBottom: 0,
          },
          opacity: 1,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {},
      },
    },
    // ALERT
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          fontWeight: 400,
          alignItems: "center",
        },
        action: {
          paddingTop: 0,
        },
      },
    },
    // TABLE
    MuiTableHead: {
      styleOverrides: {
        root: {
          fontSize: typography.body1,
          "& th": {
            fontSize: typography.body1,
            borderBottom: `1px solid ${palette.divider}`,
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${palette.divider}`,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: typography.body2,
        },
      },
    },
    // ACCORDION
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: `0 ${spacing[3]}px`,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: `${spacing[2]}px ${spacing[3]}px ${spacing[2]}px`,
        },
      },
    },

    // MENU
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: palette.gray100,
            "&:hover": {
              backgroundColor: palette.gray200,
            },
          },
        },
      },
    },

    // POPOVER
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: "0px",
          boxShadow: shadows[2],
        },
      },
    },
  },
})

export default theme
