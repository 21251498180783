import palette from "../palette"

export default {
  root: {
    textTransform: "none",
    fontWeight: "400",
    fontSize: "1rem",
    padding: "8px 16px",
    "&:hover": {
      /* color: palette.primary.main */
      backgroundColor: "transparent",
    },
  },
  label: {
    lineHeight: "normal",
  },

  // CONTAINED

  contained: {
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
    },
  },
  containedSizeSmall: {
    padding: "6px 8px",
  },
  containedSizeLarge: {
    fontSize: "1rem",
    padding: "14px 28px 15px 28px",
  },
  containedPrimary: {
    "&:hover": {
      color: `${palette.white} !important`,
    },
  },
  containedSecondary: {
    color: palette.white,
    "&:hover": {
      color: palette.white,
      backgroundColor: palette.secondary.dark,
    },
  },

  // OUTLINED

  outlined: {
    borderColor: palette.white,
    color: palette.white,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  outlinedPrimary: {
    "&:hover": {
      backgroundColor: palette.primary.main,
      color: `${palette.white} !important`,
    },
  },
  outlinedSizeLarge: {
    fontSize: "1rem",
    padding: "14px 28px 15px 28px",
  },

  // TEXT

  text: {
    padding: 0,
    "&:hover": {
      color: palette.primary.main,
    },
  },
  textSizeLarge: {
    padding: "14px 14px 15px 14px",
  },
  textPrimary: {
    "&:hover": {
      //backgroundColor: "transparent",
    },
  },
}
