import palette from "../palette";

export default {
  root: {
    backgroundColor: palette.white,
    "&$focused $notchedOutline": {
      borderWidth: 1
    },
    "&:hover $notchedOutline": {
      borderColor: palette.primary.main
    }
  }
};
