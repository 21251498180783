import palette from "../palette"

export default {
  root: {
    "&  > a > span": {
      color: palette.text.primary,
    },
    "&:hover > a > span": {
      color: palette.primary.main,
    },
  },
}
