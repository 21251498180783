const white = "#FFFFFF"
const black = "#000000"

// GRAY PALETTE

const gray50 = "#F6F6F6"
const gray100 = "#F0F0F0"
const gray200 = "#E7E7E7"
const gray500 = "#7B7B7B"
const gray800 = "#363636"
const gray900 = "#191919"

// BLUE GRAY PALETTE

const blueGray50 = "#F9FCFE"
const blueGray100 = "#EEF1F2"
const blueGray200 = "#E5E9EA"
const blueGray500 = "#7A7D7E"
const blueGray800 = "#333536"
const blueGray900 = "#1A1C1D"

export default {
  black,
  white,
  gray50,
  gray100,
  gray200,
  gray500,
  gray800,
  gray900,
  blueGray50,
  blueGray100,
  blueGray200,
  blueGray500,
  blueGray800,
  blueGray900,
  action: {
    disabled: "#E7E7E7",
  },
  primary: {
    light: "#EAC0DA",
    main: "#952E6D",
    dark: "#7B1754",
  },
  secondary: {
    light: "#E4EFF6",
    main: "#247DB3",
    dark: "#08679F",
  },
  error: {
    light: "#FB4C55",
    main: "#ED0B17",
    dark: "#CE050F",
  },
  warning: {
    light: "#FBB935",
    main: "#E69B06",
    dark: "#B87B00",
  },
  info: {
    light: "#7BA5D0",
    main: "#5383B4",
    dark: "#37699D",
  },
  success: {
    light: "#3ACF9E",
    main: "#16C68E",
    dark: "#00B378",
  },
  text: {
    primary: "#13151A",
    secondary: "#727272",
    disabled: "#b5b5b5",
  },
  background: {
    default: "#FFFFFF", //#F5F5F5
    paper: "#FFFFFF",
  },
  divider: "#F0F0F0",
  gradient: {
    main: `linear-gradient(90deg, #7B1754 -0.93%, #247DB3 100%)`,
  },
}
