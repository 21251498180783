import palette from "../palette"

export default {
  "@global": {
    "@font-face": {
      fontFamily: "Roboto",
      fontWeight: "300",
    },
    "html, body": {
      scrollBehavior: "smooth",
    },
    html: {},
    "*, *::before, *::after": {
      boxSizing: "border-box",
    },
    body: {
      backgroundColor: palette.background.default,
      color: palette.text.primary,
      fontFamily: "Roboto, Arial, sans-serif",
      fontSize: "1rem",
      fontWeight: "400",
      letterSpacing: "0.01071em",
      lineHeight: "1.43",
      margin: "0",

      "& > div": {
        // marginTop: 0
      },
    },
    "ol, ul": {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
    ol: {
      margin: 0,
      paddingLeft: 0,
      listStyle: "decimal",
    },
    ":focus": {
      outline: "none",
    },

    figure: {
      margin: 0,
      padding: 0,
    },

    a: {
      textDecoration: "none",
      color: palette.primary.main,
    },

    table: {
      width: "100%",
      border: "1px solid #F0F0F0",
      borderCollapse: "collapse",
    },
    th: {
      color: "#FFFFFF",
      padding: "8px",
      fontSize: "1rem",
      fontWeight: "400",
      lineHeight: "1.5rem",
      letterSpacing: "0.04em",
      backgroundColor: "#952E6D",
      textAlign: "left",
    },
    td: {
      display: "tableCell",
      padding: "8px",
      fontSize: "0.875rem",
      textAlign: "left",
      borderBottom: "1px solid rgba(253, 253, 253, 1)",
      borderBottomColor: "#F0F0F0",
    },
  },
}
